import React, { useState, useEffect, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { ClientContext } from '../../ClientContext';
import MortgageCalculator from '../../components/MortgageCalculator';
import FutureRentComparison from '../../components/FutureRentComparison';
import FutureHomeValueComparison from '../../components/FutureHomeValueComparison';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Detailed = () => {
  const { selectedClient, comparisonData } = useContext(ClientContext);
  const [loanData, setLoanData] = useState({});
  const [loanDetails, setLoanDetails] = useState({});
  const [paymentList, setPaymentList] = useState([]);
  const [selectedPaymentRange, setSelectedPaymentRange] = useState(0);
  const [filingStatus, setFilingStatus] = useState('Single');
  const [income, setIncome] = useState('');
  const [years, setYears] = useState(30);
  const [rentAmount, setRentAmount] = useState(0);
  const [propertyTaxes, setPropertyTaxes] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [taxRate, setTaxRate] = useState(null);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'ROI from S&P 500',
        data: [],
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
      {
        label: 'ROI from Home Value',
        data: [],
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      }
    ]
  });
  const [selectedFutureRentRange, setSelectedFutureRentRange] = useState(6);
  const [selectedEquityRange, setSelectedEquityRange] = useState(6);
  const [selectedClientState, setSelectedClientState] = useState(null);

  useEffect(() => {
    if (selectedClient && comparisonData) {
      setChartData({
        labels: comparisonData.futureRentArray ? comparisonData.futureRentArray.map(item => item.years) : [],
        datasets: [
          {
            label: 'ROI from S&P 500',
            data: comparisonData.downPaymentInSP500 ? comparisonData.downPaymentInSP500.map(item => item.returnOnInvestment) : [],
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
          },
          {
            label: 'ROI from Home Value',
            data: comparisonData.equityAndROI ? comparisonData.equityAndROI.map(item => item.returnOnInvestment) : [],
            borderColor: 'rgb(54, 162, 235)',
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
          }
        ]
      });
    }
  }, [selectedClient, comparisonData]);

  return (
    <div className="container">
      <MortgageCalculator 
        setLoanData={setLoanData}
        setLoanDetails={setLoanDetails}
        setPaymentList={setPaymentList}
        setSelectedClient={setSelectedClientState}
        setSelectedPaymentRange={setSelectedPaymentRange}
        setFilingStatus={setFilingStatus}
        setIncome={setIncome}
        setYears={setYears}
        setRentAmount={setRentAmount}
        setPropertyTaxes={setPropertyTaxes}
        setTotalInterest={setTotalInterest}
        setTaxRate={setTaxRate}
      />
      <div className="comparison-section">
        <h1>Future Rent vs Home Value</h1>
        <div className="comparison-container">
          <FutureRentComparison comparisonData={comparisonData} selectedFutureRentRange={selectedFutureRentRange} handleFutureRentRangeChange={setSelectedFutureRentRange} />
          <FutureHomeValueComparison comparisonData={comparisonData} selectedEquityRange={selectedEquityRange} handleEquityRangeChange={setSelectedEquityRange} />
          <div className="roi-comparison-chart">
            <h2>ROI Comparison: Home vs. S&P 500</h2>
            <Line data={chartData} options={{
              responsive: true,
              scales: {
                y: {
                  beginAtZero: true
                }
              }
            }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Detailed;
