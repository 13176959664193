import React, { useState, useEffect } from 'react';
import '../../authentication/Modal.css';

const AddClientModal = ({ show, onClose, onSave, initialData = {} }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (show) {
      setFirstName(initialData.first_name || '');
      setLastName(initialData.last_name || '');
      setEmail(initialData.email || '');
    }
  }, [show, initialData]);

  const handleSave = () => {
    onSave({ id: initialData.id, first_name: firstName, last_name: lastName, email });
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    show ? (
      <div className="modal-overlay" onClick={handleOverlayClick}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <h2>{initialData.id ? 'Edit Client' : 'Add Client'}</h2>
          <div className="input-group mb-4">
            <div className="input-group-prepend">
              <span className="input-group-text">First Name</span>
            </div>
            <input
              type="text"
              placeholder="First Name"
              className="form-control"
              required
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
          </div>
          <div className="input-group mb-4">
            <div className="input-group-prepend">
              <span className="input-group-text">Last Name</span>
            </div>
            <input
              type="text"
              placeholder="Last Name"
              className="form-control"
              required
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
          </div>
          <div className="input-group mb-4">
            <div className="input-group-prepend">
              <span className="input-group-text">Email</span>
            </div>
            <input
              type="email"
              placeholder="Email"
              className="form-control"
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="modal-buttons">
            <button onClick={handleSave}>Save</button>
            <button onClick={onClose}>Cancel</button>
          </div>
        </div>
      </div>
    ) : null
  );
};

export default AddClientModal;
