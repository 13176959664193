import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const ClientContext = createContext();

const ClientProvider = ({ children }) => {
  const [selectedClient, setSelectedClient] = useState(null); // Ensure this state is provided
  const [selectedHome, setSelectedHome] = useState(null);
  const [loanDetails, setLoanDetails] = useState({});
  const [comparisonData, setComparisonData] = useState({});
  const [filingStatus, setFilingStatus] = useState('Single');
  const [income, setIncome] = useState('');

  const fetchClientData = async (clientId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/get_user_inputs`, {
        params: { client_id: clientId },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        const { filing_status, income } = response.data;
        setFilingStatus(filing_status);
        setIncome(income.toLocaleString());
      }
    } catch (error) {
      console.error('Error fetching client data:', error);
    }
  };

  useEffect(() => {
    if (selectedClient) {
      fetchClientData(selectedClient.id);
    }
  }, [selectedClient]);

  return (
    <ClientContext.Provider value={{
      selectedClient,
      setSelectedClient, // Ensure this is provided
      selectedHome,
      setSelectedHome,
      loanDetails,
      setLoanDetails,
      comparisonData,
      setComparisonData,
      filingStatus,
      setFilingStatus,
      income,
      setIncome
    }}>
      {children}
    </ClientContext.Provider>
  );
};

export { ClientContext, ClientProvider };
