import React, { useState, useEffect, useContext } from 'react';
import { ClientContext } from '../../ClientContext';
import { fetchClients, fetchHomes, fetchClientData, fetchInputs } from './DataFetch';
import { saveInputs, handleAddHome } from './inputUtils';
import NumericInput from '../NumericInput';
import DropdownWithSearch from './DropdownWithSearch';
import DropdownWithSearchClients from './DropdownWithSearchClients';
import './Inputs.css';

const Inputs = () => {
  const [clients, setClients] = useState([]);
  const [homes, setHomes] = useState([]);
  const { selectedClient, setSelectedClient, selectedHome, setSelectedHome, setLoanDetails, setComparisonData, filingStatus, setFilingStatus, income, setIncome } = useContext(ClientContext);
  const [price, setPrice] = useState('0');
  const [downPayment, setDownPayment] = useState('0');
  const [isDownPaymentPercentage, setIsDownPaymentPercentage] = useState(false);
  const [interestRate, setInterestRate] = useState('7.5');
  const [propertyTaxRate, setPropertyTaxRate] = useState('1.25');
  const [homeInsuranceFee, setHomeInsuranceFee] = useState('150');
  const [PMIRate, setPMIRate] = useState('25');
  const [HOA, setHOA] = useState('300');
  const [rentAmount, setRentAmount] = useState('0');
  const [message, setMessage] = useState('');
  const [newHomeAddress, setNewHomeAddress] = useState('');
  const [newHomeType, setNewHomeType] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [homeSearchTerm, setHomeSearchTerm] = useState('');

  useEffect(() => {
    fetchClients(setClients, setMessage);
  }, []);

  useEffect(() => {
    fetchHomes(selectedClient, setHomes, setMessage);
  }, [selectedClient]);

  useEffect(() => {
    fetchClientData(selectedClient, setFilingStatus, setIncome, setMessage);
  }, [selectedClient]);

  useEffect(() => {
    fetchInputs(selectedHome, setPrice, setDownPayment, setInterestRate, setPropertyTaxRate, setHomeInsuranceFee, setPMIRate, setHOA, setRentAmount, setLoanDetails, setComparisonData, setMessage);
  }, [selectedHome, setLoanDetails, setComparisonData]);

  const clientItems = clients.map(client => ({
    id: client.id,
    label: `${client.first_name} ${client.last_name} (${client.email})`,
  }));

  const homeItems = homes.map(home => ({
    id: home.id,
    label: home.address,
  }));

  return (
    <div className="inputs-container">
      <h1 className="inputs-title">Inputs</h1>
      <div className="inputs-dropdown-group">
        <div className="inputs-dropdown">
          <DropdownWithSearchClients
            items={clientItems}
            selectedItem={selectedClient}
            setSelectedItem={setSelectedClient}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            label="Clients"
          />
          {selectedClient && (
            <div className="inputs-row">
              <div className="inputs-label-input-group">
                <label className="inputs-form-label">Filing Status:</label>
                <select value={filingStatus} onChange={(e) => setFilingStatus(e.target.value)} className="inputs-select">
                  <option value="Single">Single</option>
                  <option value="Jointly">Jointly</option>
                  <option value="HeadOfHousehold">Head Of Household</option>
                </select>
              </div>
              <div className="inputs-label-input-group">
                <label className="inputs-form-label">Income: $</label>
                <NumericInput 
                  value={income} 
                  setValue={setIncome} 
                />
              </div>
            </div>
          )}
        </div>
        {selectedClient && (
          <div className="inputs-dropdown-home-group">
            <DropdownWithSearch
              items={homeItems}
              selectedItem={selectedHome}
              setSelectedItem={setSelectedHome}
              setHomes={setHomes}
              homes={homes}
              searchTerm={homeSearchTerm}
              setSearchTerm={setHomeSearchTerm}
              label="Homes"
            />
            <button 
              className="inputs-add-home-button" 
              onClick={() => {
                setShowAddModal(true);
                setNewHomeAddress('');
                setNewHomeType('');
              }}
            >
              Add Home
            </button>
          </div>
        )}
      </div>
      <div className="inputs-form-group">
        {selectedHome && (
          <div className="inputs-table">
            {[
              { label: "Home Price", value: price, setValue: setPrice, symbol: '$' },
              { label: "Down Payment", value: downPayment, setValue: setDownPayment, symbol: isDownPaymentPercentage ? '%' : '$' },
              { label: "Interest Rate", value: interestRate, setValue: setInterestRate, symbol: '%' },
              { label: "Property Tax Rate", value: propertyTaxRate, setValue: setPropertyTaxRate, symbol: '%' },
              { label: "Home Insurance Fee", value: homeInsuranceFee, setValue: setHomeInsuranceFee, symbol: '$' },
              { label: "PMI Rate", value: PMIRate, setValue: setPMIRate, symbol: '%' },
              { label: "HOA Fees", value: HOA, setValue: setHOA, symbol: '$' },
              { label: "Rent Amount", value: rentAmount, setValue: setRentAmount, symbol: '$' }
            ].map((input, index) => (
              <div key={index} className="inputs-cell">
                <NumericInput 
                  label={input.label} 
                  value={input.value} 
                  setValue={input.setValue} 
                  symbol={input.symbol}
                />
              </div>
            ))}
          </div>
        )}
        {selectedHome && (
          <button className="inputs-save-button" onClick={() => saveInputs(selectedHome, price, downPayment, isDownPaymentPercentage, interestRate, propertyTaxRate, homeInsuranceFee, PMIRate, HOA, rentAmount, setMessage, setLoanDetails, setComparisonData, selectedClient, filingStatus, income)}>Save Inputs</button>
        )}
  
      </div>
      {showAddModal && (
        <div className="modal-overlay" onClick={() => setShowAddModal(false)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h2>Add Home</h2>
            <div className="input-group mb-4">
              <label className="input-label">Address</label>
              <input
                type="text"
                placeholder="Address"
                className="form-control"
                required
                value={newHomeAddress}
                onChange={e => setNewHomeAddress(e.target.value)}
              />
            </div>
            <div className="input-group mb-4">
              <label className="input-label">Type</label>
              <input
                type="text"
                placeholder="Type"
                className="form-control"
                required
                value={newHomeType}
                onChange={e => setNewHomeType(e.target.value)}
              />
            </div>
            <div className="modal-buttons">
              <button onClick={() => handleAddHome(selectedClient, newHomeAddress, newHomeType, setHomes, homes, setShowAddModal, setMessage, setNewHomeAddress, setNewHomeType)}>Save</button>
              <button onClick={() => setShowAddModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Inputs;
