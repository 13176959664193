import React, { useState, useRef, useEffect } from 'react';
import './DropdownWithSearch.css';

const DropdownWithSearch = ({ items, selectedItem, setSelectedItem, label }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);

  const filteredItems = items.filter(item =>
    item.label && item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown-with-search" ref={dropdownRef}>
      <label className="inputs-form-label">{label}:</label>
      <div className="dropdown-selected-item" onClick={() => setIsOpen(!isOpen)}>
        {selectedItem ? selectedItem.label : `Select ${label.toLowerCase()}`}
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          <input
            type="text"
            className="inputs-search-input"
            placeholder={`Search ${label.toLowerCase()}...`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {filteredItems.length > 0 ? (
            filteredItems.map(item => (
              <div
                key={item.id}
                className="dropdown-item"
                onClick={() => handleSelectItem(item)}
              >
                {item.label}
              </div>
            ))
          ) : (
            <div className="dropdown-item">No results found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownWithSearch;
