// fetchLoanDataClient.js
export const fetchHomes = async (clientId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_home_details?client_id=${clientId}`, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    const data = await response.json();
    if (response.ok) {
      console.log("Fetched home data:", data); // Debugging log to see fetched data
      return data;
    } else {
      throw new Error(data.message || 'Failed to fetch homes');
    }
  } catch (error) {
    console.error('Error fetching homes:', error);
    throw error;
  }
};
