import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, Outlet, NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { MdOutlineInput } from 'react-icons/md';
import { FaCalculator, FaUsers, FaHome, FaEllipsisV } from 'react-icons/fa';
import { ClientContext } from './ClientContext';
import DataContext from './dataContext';
import './HeaderLayout.css';

const HeaderLayout = () => {
  const { selectedClient, setSelectedClient } = useContext(ClientContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedNav, setSelectedNav] = useState('Dashboard');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 576);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);  // Add this line

  useEffect(() => {
    fetchCurrentUser();

    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 576);
      if (window.innerWidth > 576) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isDropdownOpen]);

  const fetchCurrentUser = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found, redirecting to login...');
      navigate('/');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/current_user`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const userData = await response.json();
      if (response.ok) {
        setCurrentUser(userData.email);
      } else {
        console.error('Fetch user failed:', userData.msg || 'No error message provided');
        navigate('/');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      navigate('/');
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/logout`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        localStorage.removeItem('token');
        navigate('/');
      } else {
        console.error('Logout failed', await response.json());
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const defaultNavItems = [
    // {
    //   icon: <FaHome className="icon" />,
    //   text: 'Dashboard',
    //   to: '/HeaderLayout'
    // },
    {
      icon: <FaUsers className="icon" />,
      text: 'Clients',
      to: '/HeaderLayout/Clients'
    },
    {
      icon: <MdOutlineInput className="icon" />,
      text: 'Inputs',
      to: '/HeaderLayout/Inputs'
    },
    {
      icon: <FaCalculator className="icon" />,
      text: 'Simple',
      to: '/HeaderLayout/Simple'
    },
    {
      icon: <FaCalculator className="icon" />,
      text: 'Detailed',
      to: '/HeaderLayout/Detailed'
    }
  ];

  return (
    <div className="layout-container">
      <header className="header d-flex justify-content-between align-items-center">
        <div className="logo">
          <img src={process.env.PUBLIC_URL + "/assets/pf.png"} alt="logo text" className="logo-text" />
        </div>
        <div className="user-info d-flex align-items-center" ref={dropdownRef}>
          <button className="custom-btn" id="dropdown-basic" onClick={toggleDropdown}>
            {currentUser ? currentUser : 'Account'}
          </button>
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <div className="dropdown-item" onClick={() => navigate('/HeaderLayout/Settings')}>Settings</div>
              <div className="dropdown-item" onClick={handleLogout}>Sign Out</div>
            </div>
          )}
          {isMobileView && (
            <button className="mobile-menu-toggle" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
              <FaEllipsisV />
            </button>
          )}
        </div>
        {isMobileMenuOpen && (
          <div className="mobile-menu">
            {defaultNavItems.map((item, index) => (
              <div key={index} className="mobile-menu-item">
                <NavLink
                  className={`nav-link ${item.text === selectedNav ? 'active' : ''}`}
                  to={item.to}
                  onClick={() => {
                    setSelectedNav(item.text);
                    setIsMobileMenuOpen(false);
                  }}
                >
                  {item.icon}
                  <span>{item.text}</span>
                </NavLink>
              </div>
            ))}
          </div>
        )}
      </header>
      <div className="sidebar d-flex flex-column justify-content-between">
        <ul className="navbar-nav flex-column">
          <li className="nav-item" style={{ marginTop: '1rem' }}></li>
          {defaultNavItems.map((item, index) => (
            <li className="nav-item" key={index}>
              <NavLink
                className={`nav-link ${item.text === selectedNav ? 'active' : ''}`}
                to={item.to}
                onClick={() => setSelectedNav(item.text)}
              >
                {item.icon}
                <span>{item.text}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <main className="main-content">
        <DataContext.Provider value={{
          currentUser,
          selectedNav,
          setSelectedNav
        }}>
          <Outlet />
        </DataContext.Provider>
      </main>
    </div>
  );
};

export default HeaderLayout;
