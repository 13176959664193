import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { calculateComparison, formatNumber } from './mathUtils';

export const generatePDF = (selectedClient, loanData, loanDetails, paymentList, selectedPaymentRange, comparisonResults, filingStatus, income, years, rentAmount, propertyTaxes, totalInterest) => {
  const doc = new jsPDF();

  const clientDetails = [
    { label: "Home Price", value: loanData.home_price, symbol: '$' },
    { label: "Down Payment", value: loanData.down_payment, symbol: '$' },
    { label: "Interest Rate", value: loanData.interest_rate, symbol: '%' },
    { label: "Property Tax Rate", value: loanData.property_tax_rate, symbol: '%' },
    { label: "Home Insurance Fee", value: loanData.home_insurance_fee, symbol: '$' },
    { label: "PMI Rate", value: loanData.pmi_rate, symbol: '%' },
    { label: "HOA Fees", value: loanData.hoa, symbol: '$' },
    { label: "Rent Amount", value: loanData.rent_amount, symbol: '$' },
    { label: "Filing Status", value: filingStatus, symbol: '' },
    { label: "Income", value: income, symbol: '$' }
  ];

  const loanInfo = [
    { label: "Loan Amount", value: loanDetails?.loanAmount?.toFixed(2) || '0.00', symbol: '$' },
    { label: "Interest Rate", value: loanData.interest_rate?.toFixed(2) || '0.00', symbol: '%' },
    { label: "Years", value: years, symbol: '' },
    { label: "Payments per Year", value: 12, symbol: '' },
    { label: "Total Number of Payments", value: years * 12, symbol: '' },
    { label: "Payment per Period", value: loanDetails?.paymentPerPeriod?.toFixed(2) || '0.00', symbol: '$' },
    { label: "Property Taxes", value: loanDetails?.propertyTaxes?.toFixed(2) || '0.00', symbol: '$' },
    { label: "Home Insurance Fee", value: loanData.home_insurance_fee?.toFixed(2) || '0.00', symbol: '$' },
    { label: "PMI Cost", value: loanDetails?.PMICost?.toFixed(2) || '0.00', symbol: '$' },
    { label: "HOA Fees", value: loanData.hoa?.toFixed(2) || '0.00', symbol: '$' },
    { label: "Total PITI", value: loanDetails?.totalPITI?.toFixed(2) || '0.00', symbol: '$' },
    { label: "Total Interest Paid", value: totalInterest?.toFixed(2) || '0.00', symbol: '$' },
    { label: "Sum of Interest (Selected Range)", value: paymentList.slice(0, selectedPaymentRange).reduce((sum, payment) => sum + (payment.interestAmount || 0), 0).toFixed(2), symbol: '$' }
  ];

  const loanPaymentsHeaders = ["Payment (Number)", "Payment ($)", "Interest", "Principal", "Loan"];
  const loanPaymentsData = paymentList.slice(0, selectedPaymentRange).map(payment => [
    payment.paymentNumber,
    `$${formatNumber((payment.paymentAmount || 0).toFixed(2))}`,
    `$${formatNumber((payment.interestAmount || 0).toFixed(2))}`,
    `$${formatNumber((payment.principalAmount || 0).toFixed(2))}`,
    `$${formatNumber((payment.remainingBalance || 0).toFixed(2))}`
  ]);

  const rentVsOwnHeaders = ["Years", "Rent Expense", "Own Net Wealth", "SP500 Value", "ROI SP500"];
  const rentVsOwnData = comparisonResults.map(result => [
    result.id,
    `$${formatNumber((result.rentExpense || 0).toFixed(2))}`,
    `$${formatNumber((result.netWealth || 0).toFixed(2))}`,
    `$${formatNumber((result.sp500Value || 0).toFixed(2))}`,
    `$${formatNumber((result.roiSP500 || 0).toFixed(2))}`
  ]);

  doc.text("Client Details", 14, 20);
  doc.autoTable({
    startY: 30,
    body: clientDetails.map(item => [item.label, `${item.symbol}${item.value}`]),
    theme: 'plain',
    styles: { fontSize: 10, cellPadding: 2 }
  });

  doc.text("Loan Information", 14, doc.autoTable.previous.finalY + 10);
  doc.autoTable({
    startY: doc.autoTable.previous.finalY + 20,
    body: loanInfo.map(item => [item.label, `${item.symbol}${item.value}`]),
    theme: 'plain',
    styles: { fontSize: 10, cellPadding: 2 }
  });

  doc.text("Loan Payments", 14, doc.autoTable.previous.finalY + 10);
  doc.autoTable({
    startY: doc.autoTable.previous.finalY + 20,
    head: [loanPaymentsHeaders],
    body: loanPaymentsData,
    theme: 'plain',
    styles: { fontSize: 10, cellPadding: 2 }
  });

  doc.text("Rent vs Own Comparison", 14, doc.autoTable.previous.finalY + 10);
  doc.autoTable({
    startY: doc.autoTable.previous.finalY + 20,
    head: [rentVsOwnHeaders],
    body: rentVsOwnData,
    theme: 'plain',
    styles: { fontSize: 10, cellPadding: 2 }
  });

  doc.save(`${selectedClient.name}-details.pdf`);
};
