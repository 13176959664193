import { calculateLoanDetails, calculateComparison, formatNumber } from '../../../utils/mathUtils';

export const fetchClients = async (setClients, setMessage) => {
  const token = localStorage.getItem('token');
  if (!token) {
    setMessage('No token found, please log in.');
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_clients`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    const result = await response.json();
    if (response.ok) {
      setClients(result);
    } else {
      setMessage(result.status);
    }
  } catch (error) {
    setMessage(`An error occurred: ${error.message}`);
  }
};

export const fetchHomes = async (selectedClient, setHomes, setMessage) => {
  if (!selectedClient) return;

  const token = localStorage.getItem('token');
  if (!token) {
    setMessage('No token found, please log in.');
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_homes?client_id=${selectedClient.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    const result = await response.json();
    if (response.ok) {
      setHomes(result);
    } else {
      setMessage(result.status);
    }
  } catch (error) {
    setMessage(`An error occurred: ${error.message}`);
  }
};

export const fetchClientData = async (selectedClient, setFilingStatus, setIncome, setMessage) => {
  const token = localStorage.getItem('token');
  if (!token) {
    setMessage('No token found, please log in.');
    return;
  }

  if (!selectedClient) {
    setMessage('Please select a client.');
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_client_data?client_id=${selectedClient.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    const result = await response.json();
    if (response.ok) {
      setFilingStatus(result.filing_status);
      setIncome(formatNumber(result.income));
    } else {
      setMessage(result.status);
    }
  } catch (error) {
    setMessage(`An error occurred: ${error.message}`);
  }
};

export const fetchInputs = async (selectedHome, setPrice, setDownPayment, setInterestRate, setPropertyTaxRate, setHomeInsuranceFee, setPMIRate, setHOA, setRentAmount, setLoanDetails, setComparisonData, setMessage) => {
  const token = localStorage.getItem('token');
  if (!token) {
    setMessage('No token found, please log in.');
    return;
  }

  if (!selectedHome) {
    setMessage('Please select a home.');
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_inputs?home_id=${selectedHome.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    const result = await response.json();
    if (response.ok) {
      setPrice(formatNumber(result.home_price));
      setDownPayment(formatNumber(result.down_payment));
      setInterestRate(result.interest_rate.toString());
      setPropertyTaxRate(result.property_tax_rate.toString());
      setHomeInsuranceFee(formatNumber(result.home_insurance_fee));
      setPMIRate(result.pmi_rate.toString());
      setHOA(formatNumber(result.hoa));
      setRentAmount(formatNumber(result.rent_amount));

      // Calculate loan details and comparison data
      const loanDetails = calculateLoanDetails(result, 30); // Assuming 30 years by default
      setLoanDetails(loanDetails);

      const comparisonData = calculateComparison(result, 30, result.rent_amount, loanDetails.paymentList, loanDetails.totalInterest, loanDetails.propertyTaxes);
      setComparisonData(comparisonData);
    } else {
      setMessage(result.status);
    }
  } catch (error) {
    setMessage(`An error occurred: ${error.message}`);
  }
};