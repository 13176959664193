import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineUser, AiOutlineLock, AiOutlineMail } from 'react-icons/ai';
import './Modal.css';

const Signup = ({ onClose, openLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [signupError, setSignupError] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleSignup = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      alert("Passwords don't match!");
      setLoading(false);
      return;
    }

    const signupData = {
      username: username,
      password: password,
      email: email,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(signupData),
      });

      if (response.ok) {
        setSuccessMessage('Registration successful! Please check your email to verify your account.');
      } else {
        const text = await response.text();
        setSignupError(text);
      }
    } catch (error) {
      console.error('Error:', error);
      setSignupError('An error occurred during signup.');
    } finally {
      setLoading(false);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        {successMessage ? (
          <div>
            <p>{successMessage}</p>
            <button className="login-btn" onClick={onClose}>Close</button>
          </div>
        ) : (
          <form onSubmit={handleSignup}>
            <div className="input-group mb-4">
              <div className="input-group-prepend">
                <span className="input-group-text"><AiOutlineUser /></span>
              </div>
              <input
                type="text"
                placeholder="Username"
                className="form-control"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="input-group mb-4">
              <div className="input-group-prepend">
                <span className="input-group-text"><AiOutlineMail /></span>
              </div>
              <input
                type="email"
                placeholder="Email"
                className="form-control"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-group mb-4">
              <div className="input-group-prepend">
                <span className="input-group-text"><AiOutlineLock /></span>
              </div>
              <input
                type="password"
                placeholder="Password"
                className="form-control"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="input-group mb-4">
              <div className="input-group-prepend">
                <span className="input-group-text"><AiOutlineLock /></span>
              </div>
              <input
                type="password"
                placeholder="Confirm Password"
                className="form-control"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <button type="submit" className="signup-btn" style={{ cursor: loading ? 'wait' : 'default' }}>
              Sign Up
            </button>
            {signupError && <p className="text-danger mt-3">{signupError}</p>}
            <div className="d-flex justify-content-center mt-5 bottom-text">
              <small><Link to="#" onClick={() => { onClose(); openLogin(); }}>Already have an account? Login</Link></small>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Signup;
