import React from 'react';
import './ComparisonTable.css';

const ComparisonTable = ({ homes }) => {
  const calculateMetrics = (home) => {
    const totalLoanAmount = home.home_price - home.down_payment;
    const DTI = calculateDTI(home); // Placeholder function
    const LTV = (totalLoanAmount / home.home_price) * 100;
    const points = home.points || 0; // Assuming points might be available
    const prepaidInterestAmount = calculatePrepaidInterest(home); // Placeholder function
    const cashToClose = calculateCashToClose(home); // Placeholder function

    return {
      totalLoanAmount: `$${totalLoanAmount.toFixed(2)}`,
      DTI: `${DTI.toFixed(2)}%`,
      LTV: `${LTV.toFixed(2)}%`,
      points: `$${points.toFixed(2)}`,
      prepaidInterestAmount: `$${prepaidInterestAmount.toFixed(2)}`,
      cashToClose: `$${cashToClose.toFixed(2)}`,
    };
  };

  const calculateDTI = (home) => {
    // Implement DTI calculation based on home and client data
    return 0; // Placeholder
  };

  const calculatePrepaidInterest = (home) => {
    // Implement prepaid interest calculation based on home data
    return 0; // Placeholder
  };

  const calculateCashToClose = (home) => {
    // Implement cash to close calculation based on home data
    return 0; // Placeholder
  };

  return (
    <div className="comparison-table">
      <h2>Comparison Table</h2>
      <table>
        <thead>
          <tr>
            <th></th>
            {homes.map((home) => (
              <th key={home.id}>{home.address}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[
            'Total Loan Amount',
            'DTI',
            'LTV',
            'Points',
            'Prepaid Int. Amount',
            'Cash to Close',
          ].map((metric) => (
            <tr key={metric}>
              <td>{metric}</td>
              {homes.map((home) => (
                <td key={home.id}>
                  {calculateMetrics(home)[metric.replace(/\s/g, '').toLowerCase()]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ComparisonTable;
