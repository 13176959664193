import React from 'react';
import Navbar from './Navbar';
import Hero from './hero/Hero';
import './LandingPage.css';

const LandingPage = ({ openLogin, openSignup }) => {
  return (
    <div className="landing-page">
      <Navbar openLogin={openLogin} openSignup={openSignup} />
      <Hero openSignup={openSignup} />
    </div>
  );
}

export default LandingPage;
