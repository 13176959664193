// src/components/FutureRentComparison.js
import React from 'react';
import { formatNumber } from '../../utils/mathUtils';
import './FutureRentComparison.css';

const FutureRentComparison = ({ comparisonData, selectedFutureRentRange, handleFutureRentRangeChange }) => (
  <div className="future-rent">
    <h2>Future Rent</h2>
    <label className="form-label">
      Select Future Rent Range:
      <select value={selectedFutureRentRange} onChange={handleFutureRentRangeChange}>
        {Array.from({ length: Math.ceil((comparisonData.futureRentArray?.length || 0) / 6) }, (_, i) => (i + 1) * 6).map((range) => (
          <option key={range} value={range}>{range}</option>
        ))}
      </select>
    </label>
    <table>
      <thead>
        <tr>
          <th>Years</th>
          <th>Future Rent</th>
          <th>Down Payment in S&P 500</th>
          <th>ROI on S&P 500 (%)</th>
        </tr>
      </thead>
      <tbody>
        {comparisonData.futureRentArray?.slice(0, selectedFutureRentRange).map((item, index) => (
          <tr key={index}>
            <td>{formatNumber(item.years)}</td>
            <td>${formatNumber(item.amount.toFixed(2))}</td>
            <td>${formatNumber(comparisonData.downPaymentInSP500[index]?.amount.toFixed(2))}</td>
            <td>{formatNumber(comparisonData.downPaymentInSP500[index]?.returnOnInvestment)}%</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default FutureRentComparison;
