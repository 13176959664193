import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ClientContext } from '../../ClientContext';
import './Goals.css';

const Goals = () => {
  const { selectedClient } = useContext(ClientContext);
  const [goals, setGoals] = useState({});
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchGoals = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setMessage('No token found, please log in.');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_goals?client_id=${selectedClient.id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const result = await response.json();
        if (response.ok) {
          setGoals(result);
        } else {
          setMessage(result.status);
        }
      } catch (error) {
        setMessage(`An error occurred: ${error.message}`);
      }
    };

    if (selectedClient) {
      fetchGoals();
    }
  }, [selectedClient]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setGoals({
      ...goals,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setMessage('No token found, please log in.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_goals`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...goals, client_id: selectedClient.id })
      });

      const result = await response.json();
      if (response.ok) {
        setMessage('Goals updated successfully.');
      } else {
        setMessage(result.status);
      }
    } catch (error) {
      setMessage(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className="goals">
      <h1>Goals for {selectedClient.name}</h1>
      {message && <p>{message}</p>}
      <form>
        <div className="goal-item">
          <label>
            <input type="checkbox" name="support_retirement" checked={goals.support_retirement || false} onChange={handleChange} />
            Support retirement
          </label>
        </div>
        <div className="goal-item">
          <label>
            <input type="checkbox" name="pay_off_debt" checked={goals.pay_off_debt || false} onChange={handleChange} />
            Pay off debt
          </label>
        </div>
        <div className="goal-item">
          <label>
            <input type="checkbox" name="lower_interest_rate" checked={goals.lower_interest_rate || false} onChange={handleChange} />
            Lower interest rate
          </label>
        </div>
        <div className="goal-item">
          <label>
            <input type="checkbox" name="purchase_new_home" checked={goals.purchase_new_home || false} onChange={handleChange} />
            Purchase new home
          </label>
        </div>
        <div className="goal-item">
          <label>
            <input type="checkbox" name="reduce_payment" checked={goals.reduce_payment || false} onChange={handleChange} />
            Reduce payment
          </label>
          <input type="number" name="reduce_payment_amount" value={goals.reduce_payment_amount || 0} onChange={handleChange} />
        </div>
        <div className="goal-item">
          <label>
            <input type="checkbox" name="tap_into_equity" checked={goals.tap_into_equity || false} onChange={handleChange} />
            Tap into equity
          </label>
        </div>
        <div className="goal-item">
          <label>
            <input type="checkbox" name="other" checked={goals.other || false} onChange={handleChange} />
            Other
          </label>
          <input type="text" name="other_description" value={goals.other_description || ''} onChange={handleChange} />
        </div>
      </form>
      <button onClick={handleSubmit}>Save Goals</button>
    </div>
  );
};

export default Goals;
