// src/components/LoanInfoTable.js
import React from 'react';
import { calculateLoanDetails, formatNumber } from '../../utils/mathUtils';
import { Tooltip } from 'react-tooltip';
import './LoanInfoTable.css';

const LoanInfoTable = ({ loanData, years }) => {
  const loanDetails = calculateLoanDetails(loanData, years);

  const tooltipContent = {
    loanAmount: `Loan Amount = Home Price - Down Payment<br/>${formatNumber(loanData.home_price)} - ${formatNumber(loanData.down_payment)}<br/>= ${formatNumber(loanDetails.loanAmount)}`,
    interestRate: `Interest Rate = Provided by user<br/>${loanData.interest_rate}%`,
    years: `Years = Selected number of years for the loan<br/>${years} years`,
    paymentsPerYear: `Payments per Year = Fixed value<br/>12 payments per year`,
    totalNumPayments: `Total Number of Payments = Years * Payments per Year<br/>${years} * 12<br/>= ${(years ?? 0) * 12}`,
    paymentPerPeriod: `Payment per Period = Calculated using PMT formula<br/>${formatNumber(loanDetails.paymentPerPeriod)}`,
    propertyTaxes: `Property Taxes = Home Price * Property Tax Rate / Payments per Year<br/>${formatNumber(loanData.home_price)} * ${loanData.property_tax_rate}% / 12<br/>= ${formatNumber(loanDetails.propertyTaxes)}`,
    homeInsuranceFee: `Home Insurance Fee = Provided by user<br/>${formatNumber(loanData.home_insurance_fee)}`,
    PMICost: `PMI Cost = Loan Amount * PMI Rate / Payments per Year<br/>${formatNumber(loanDetails.loanAmount)} * ${loanData.pmi_rate}% / 12<br/>= ${formatNumber(loanDetails.PMICost)}`,
    hoa: `HOA Fees = Provided by user<br/>${formatNumber(loanData.hoa)}`,
    totalPITI: `Total PITI = Sum of Payment per Period, Property Taxes, Home Insurance Fee, PMI Cost, and HOA Fees<br/>${formatNumber(loanDetails.paymentPerPeriod)} + ${formatNumber(loanDetails.propertyTaxes)} + ${formatNumber(loanData.home_insurance_fee)} + ${formatNumber(loanDetails.PMICost)} + ${formatNumber(loanData.hoa)}<br/>= ${formatNumber(loanDetails.totalPITI)}`
  };

  return (
    <>
      <table className="loan-table">
        <tbody>
          {[
            { label: "Loan Amount", value: loanDetails.loanAmount ?? 0, symbol: '$', tooltip: tooltipContent.loanAmount },
            { label: "Interest Rate", value: loanData.interest_rate ?? 0, symbol: '%', tooltip: tooltipContent.interestRate },
            { label: "Years", value: years ?? 0, symbol: '', tooltip: tooltipContent.years },
            { label: "Payments per Year", value: 12, symbol: '', tooltip: tooltipContent.paymentsPerYear },
            { label: "Total Number of Payments", value: (years ?? 0) * 12, symbol: '', tooltip: tooltipContent.totalNumPayments },
            { label: "Payment per Period", value: loanDetails.paymentPerPeriod ?? 0, symbol: '$', tooltip: tooltipContent.paymentPerPeriod },
            { label: "Property Taxes", value: loanDetails.propertyTaxes ?? 0, symbol: '$', tooltip: tooltipContent.propertyTaxes },
            { label: "Home Insurance Fee", value: loanData.home_insurance_fee ?? 0, symbol: '$', tooltip: tooltipContent.homeInsuranceFee },
            { label: "PMI Cost", value: loanDetails.PMICost ?? 0, symbol: '$', tooltip: tooltipContent.PMICost },
            { label: "HOA Fees", value: loanData.hoa ?? 0, symbol: '$', tooltip: tooltipContent.hoa },
            { label: "Total PITI", value: loanDetails.totalPITI ?? 0, symbol: '$', tooltip: tooltipContent.totalPITI }
          ].map((item, index) => (
            <tr key={index}>
              <td>{item.label}:</td>
              <td>
                <span
                  data-tooltip-id={`tooltip-${index}`}
                  data-tooltip-html={item.tooltip}
                  data-tooltip-delay-show="300"
                >
                  {item.symbol === '%' ? `${formatNumber(item.value.toFixed(2))}${item.symbol}` : `${item.symbol}${formatNumber(item.value.toFixed(2))}`}
                </span>
                <Tooltip id={`tooltip-${index}`} place="top" effect="solid" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default LoanInfoTable;
