import React, { useState } from 'react';
import { formatNumber } from '../../utils/mathUtils';
import { Tooltip } from 'react-tooltip';
import './LoanDetails.css';
import { saveEditedLoanDetail } from './LoanUtils';

const LoanDetails = ({ loanData, setLoanData }) => {
  const [editingField, setEditingField] = useState(null);
  const [editedValue, setEditedValue] = useState('');

  const handleDoubleClick = (field) => {
    setEditingField(field);
    setEditedValue(loanData[field]);
  };

  const handleBlur = async () => {
    if (editedValue !== loanData[editingField]) {
      const updatedLoanData = { ...loanData, [editingField]: editedValue };
      setLoanData(updatedLoanData);
      await saveEditedLoanDetail(editingField, editedValue); // Save to the database
    }
    setEditingField(null);
    setEditedValue('');
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      handleBlur();
    } else if (e.key === 'Escape') {
      setEditingField(null);
      setEditedValue('');
    }
  };

  const formatValue = (value) => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? 'N/A' : formatNumber(parsedValue.toFixed(2));
  };

  const tooltipContent = {
    home_price: `Home Price = Provided by user<br/>${formatValue(loanData.home_price)}`,
    down_payment: `Down Payment = Provided by user<br/>${formatValue(loanData.down_payment)}`,
    interest_rate: `Interest Rate = Provided by user<br/>${loanData.interest_rate}%`,
    property_tax_rate: `Property Tax Rate = Provided by user<br/>${loanData.property_tax_rate}%`,
    home_insurance_fee: `Home Insurance Fee = Provided by user<br/>${formatValue(loanData.home_insurance_fee)}`,
    pmi_rate: `PMI Rate = Provided by user<br/>${loanData.pmi_rate}%`,
    hoa: `HOA Fees = Provided by user<br/>${formatValue(loanData.hoa)}`,
    rent_amount: `Rent Amount = Provided by user<br/>${formatValue(loanData.rent_amount)}`,
    filing_status: `Filing Status = Provided by user<br/>${loanData.filing_status}`,
    income: `Income = Provided by user<br/>${formatValue(loanData.income)}`
  };

  const renderField = (key, label) => (
    <p key={key}>
      {label}: 
      {editingField === key ? (
        <input
          type="text"
          value={editedValue}
          onChange={(e) => setEditedValue(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className="editable-input"
          autoFocus
        />
      ) : (
        <span
          data-tooltip-id={`${key}-tooltip`}
          data-tooltip-html={tooltipContent[key]}
          data-tooltip-delay-show="300"
          onDoubleClick={() => handleDoubleClick(key)}
          className="editable-field"
        >
          {key.includes('rate') ? `${loanData[key]}%` : `$${formatValue(loanData[key])}`}
        </span>
      )}
      <Tooltip id={`${key}-tooltip`} place="top" effect="solid" />
    </p>
  );

  return (
    <div className="loan-details">
      {renderField('home_price', 'Home Price')}
      {renderField('down_payment', 'Down Payment')}
      {renderField('interest_rate', 'Interest Rate')}
      {renderField('property_tax_rate', 'Property Tax Rate')}
      {renderField('home_insurance_fee', 'Home Insurance Fee')}
      {renderField('pmi_rate', 'PMI Rate')}
      {renderField('hoa', 'HOA Fees')}
      {renderField('rent_amount', 'Rent Amount')}
      {renderField('filing_status', 'Filing Status')}
      {renderField('income', 'Income')}
    </div>
  );
};

export default LoanDetails;
