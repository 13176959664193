// src/components/MainComponent.js
import React from 'react';
import Login from './Logint';
import Signup from './signup';

const MainComponent = ({ openLogin, openSignup, closeLogin, closeSignup, isLoginOpen, isSignupOpen }) => {
  return (
    <div>
      {isLoginOpen && <Login onClose={closeLogin} openSignup={openSignup} />}
      {isSignupOpen && <Signup onClose={closeSignup} openLogin={openLogin} />}
    </div>
  );
};

export default MainComponent;
