export const saveEditedLoanDetail = async (field, value) => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('No token found, please log in.');
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/save_loan_detail`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ field, value })
    });

    if (response.ok) {
      console.log('Loan detail saved successfully.');
    } else {
      const result = await response.json();
      console.error('Error saving loan detail:', result.status);
    }
  } catch (error) {
    console.error('An error occurred while saving loan detail:', error);
  }
};
