// src/components/FutureHomeValueComparison.js
import React from 'react';
import { formatNumber } from '../../utils/mathUtils';
import './FutureHomeValueComparison.css';

const FutureHomeValueComparison = ({ comparisonData, selectedEquityRange, handleEquityRangeChange }) => (
  <div className="future-own">
    <h2>Future Home Value</h2>
    <label className="form-label">
      Select Equity Range:
      <select value={selectedEquityRange} onChange={handleEquityRangeChange}>
        {Array.from({ length: Math.ceil((comparisonData.equityAndROI?.length || 0) / 6) }, (_, i) => (i + 1) * 6).map((range) => (
          <option key={range} value={range}>{range}</option>
        ))}
      </select>
    </label>
    <table>
      <thead>
        <tr>
          <th>Years</th>
          <th>Future Home Value</th>
          <th>Equity Gained</th>
          <th>ROI (%)</th>
        </tr>
      </thead>
      <tbody>
        {comparisonData.equityAndROI?.slice(0, selectedEquityRange).map((item, index) => (
          <tr key={index}>
            <td>{formatNumber(item.years)}</td>
            <td>${formatNumber(item.futureValue.toFixed(2))}</td>
            <td>${formatNumber(item.equityGained.toFixed(2))}</td>
            <td>{formatNumber(item.returnOnInvestment)}%</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default FutureHomeValueComparison;
