//dataContext.js
import React, { createContext, useState } from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [price, setPrice] = useState(0);
  const [downPayment, setDownPayment] = useState(0);
  const [interestRate, setInterestRate] = useState(7.5);
  const [propertyTaxRate, setPropertyTaxRate] = useState(1.25);
  const [homeInsuranceFee, setHomeInsuranceFee] = useState(150);
  const [PMIRate, setPMIRate] = useState(25);
  const [HOA, setHOA] = useState(300);

  const formatNumber = (num) => {
    return Number(num).toLocaleString();
  };

  const handleNumericChange = (value, setNumericState) => {
    const numericValue = parseFloat(value.replace(/,/g, ''));
    if (!isNaN(numericValue)) {
      setNumericState(numericValue);
    } else {
      setNumericState(0);
    }
  };

  const handleBlur = (value, setDisplayState) => {
    setDisplayState(formatNumber(value));
  };

  return (
    <DataContext.Provider
      value={{
        price,
        setPrice,
        downPayment,
        setDownPayment,
        interestRate,
        setInterestRate,
        propertyTaxRate,
        setPropertyTaxRate,
        homeInsuranceFee,
        setHomeInsuranceFee,
        PMIRate,
        setPMIRate,
        HOA,
        setHOA,
        formatNumber,
        handleNumericChange,
        handleBlur,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
