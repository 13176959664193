import React, { useState } from 'react';
import { formatNumber } from '../../utils/mathUtils';
import './ComparisonSection.css';
import { saveEditedComparisonDetail } from './ComparisonUtils';

const ComparisonSection = ({
  comparisonResults = [],
  setComparisonResults,
  expandedYear,
  setExpandedYear,
  paymentList,
  propertyTaxes,
  years,
  totalInterest,
  rentAmount,
  rentIncreases
}) => {
  const [expandedRentYear, setExpandedRentYear] = useState(null);
  const [highlightedCell, setHighlightedCell] = useState({ year: null, type: null });
  const [editingField, setEditingField] = useState({ year: null, field: null });
  const [editedValue, setEditedValue] = useState('');

  const handleExpandYear = (year) => {
    if (expandedYear === year) {
      setExpandedYear(null);
    } else {
      setExpandedYear(year);
      setExpandedRentYear(null);
    }
  };

  const handleExpandRentYear = (year) => {
    if (expandedRentYear === year) {
      setExpandedRentYear(null);
    } else {
      setExpandedRentYear(year);
      setExpandedYear(null);
    }
  };

  const calculateFutureRentExpenses = (rentAmount, rentIncreases, i) => {
    return 12 * rentAmount * ((Math.pow(1 + rentIncreases, i) - 1) / rentIncreases);
  };

  const handleCellClick = (year, type) => {
    setHighlightedCell({ year, type });
  };

  const handleDoubleClick = (year, field) => {
    setEditingField({ year, field });
    const value = comparisonResults.find(result => result.years === year).details[field];
    setEditedValue(value.toString());
  };

  const handleBlur = async (year, field) => {
    if (editedValue !== comparisonResults.find(result => result.years === year).details[field].toString()) {
      const updatedComparisonResults = comparisonResults.map(result => {
        if (result.years === year) {
          return {
            ...result,
            details: {
              ...result.details,
              [field]: parseFloat(editedValue)
            }
          };
        }
        return result;
      });
      const clientId = comparisonResults.find(result => result.years === year).client_id;
      await saveEditedComparisonDetail(field, editedValue, clientId);
      setComparisonResults(updatedComparisonResults);
    }
    setEditingField({ year: null, field: null });
    setEditedValue('');
  };

  const handleKeyDown = async (e, year, field) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      handleBlur(year, field);
    }
  };

  return (
    <div className="comparison-section">
      <h1>Rent vs Own Comparison</h1>
      <div className="comparison-container">
        <table>
          <thead>
            <tr>
              <th>Years</th>
              <th>Rent Expense</th>
              <th>Own Net Wealth</th>
              <th>SP500 Value</th>
            </tr>
          </thead>
          <tbody>
            {comparisonResults.map((result) => (
              <React.Fragment key={result.years}>
                <tr>
                  <td>{result.years}</td>
                  <td
                    className={highlightedCell.year === result.years && highlightedCell.type === 'rent' ? 'highlighted' : ''}
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => { handleExpandRentYear(result.years); handleCellClick(result.years, 'rent'); }}
                  >
                    ${formatNumber(result.futureRentExpense.toFixed(2))}
                  </td>
                  <td
                    className={highlightedCell.year === result.years && highlightedCell.type === 'own' ? 'highlighted' : ''}
                    style={{ color: 'green', cursor: 'pointer' }}
                    onClick={() => { handleExpandYear(result.years); handleCellClick(result.years, 'own'); }}
                  >
                    ${formatNumber(result.netWealth.toFixed(2))}
                  </td>
                  <td
                    className={highlightedCell.year === result.years && highlightedCell.type === 'sp500' ? 'highlighted' : ''}
                    style={{ color: 'black', cursor: 'pointer' }}
                    onClick={() => handleCellClick(result.years, 'sp500')}
                  >
                    ${formatNumber(result.sp500Value.toFixed(2))}
                  </td>
                </tr>
                {expandedYear === result.years && result.details && (
                  <tr>
                    <td colSpan="4">
                      <div className="comparison-box">
                        <p>Years: {result.years}</p>
                        <p>Monthly Mortgage Payment: ${formatNumber(result.details.monthlyMortgagePayment)}</p>
                        <p>Home Value Over Time: ${formatNumber(result.details.homeValueIncreases)}</p>
                        <p>Outstanding Loan Balance: ${formatNumber(result.details.outstandingLoanBalance)}</p>
                        <p>Net Wealth: ${formatNumber(result.netWealth.toFixed(2))}</p>
                      </div>
                    </td>
                  </tr>
                )}
                {expandedRentYear === result.years && result.details && (
                  <tr>
                    <td colSpan="4">
                      <div className="comparison-box">
                        <p>Years: {result.years}</p>
                        <p>Rent Amount: 
                          {editingField.year === result.years && editingField.field === 'rentAmount' ? (
                            <input
                              type="text"
                              value={editedValue}
                              onChange={(e) => setEditedValue(e.target.value)}
                              onBlur={() => handleBlur(result.years, 'rentAmount')}
                              onKeyDown={(e) => handleKeyDown(e, result.years, 'rentAmount')}
                              autoFocus
                              className="editable-field"
                            />
                          ) : (
                            <span
                              onDoubleClick={() => handleDoubleClick(result.years, 'rentAmount')}
                              className="editable-span"
                            >
                              ${formatNumber(result.details.rentAmount)}
                            </span>
                          )}
                        </p>
                        <p>Rent Increases: 
                          {editingField.year === result.years && editingField.field === 'rentIncreases' ? (
                            <input
                              type="text"
                              value={editedValue}
                              onChange={(e) => setEditedValue(e.target.value)}
                              onBlur={() => handleBlur(result.years, 'rentIncreases')}
                              onKeyDown={(e) => handleKeyDown(e, result.years, 'rentIncreases')}
                              autoFocus
                              className="editable-field"
                            />
                          ) : (
                            <span
                              onDoubleClick={() => handleDoubleClick(result.years, 'rentIncreases')}
                              className="editable-span"
                            >
                              {formatNumber((result.details.rentIncreases * 100).toFixed(2))}%
                            </span>
                          )}
                        </p>
                        <p>Calculation: 12 * ${formatNumber(result.details.rentAmount.toFixed(2))} * ((Math.pow(1 + {result.details.rentIncreases}, {result.years}) - 1) / {result.details.rentIncreases})</p>
                        <p>Future Rent Expense: ${formatNumber(calculateFutureRentExpenses(result.details.rentAmount, result.details.rentIncreases, result.years).toFixed(2))}</p>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ComparisonSection;
