import React from 'react';
import './NumericInput.css';

const NumericInput = ({ label, value, setValue, symbol }) => {
  const handleNumericChange = (value) => {
    if (value === '' || /^[0-9.,]+$/.test(value)) {
      const numericValue = value.replace(/,/g, '');
      const parts = numericValue.split('.');
      let formattedValue = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (parts[1] !== undefined) {
        formattedValue += `.${parts[1]}`;
      }
      setValue(formattedValue);
    }
  };

  return (
    <div className="label-input-group">
      <label className="form-label">{label} {symbol}</label>
      <input
        className="input-field"
        type="text"
        value={value}
        onChange={(e) => handleNumericChange(e.target.value)}
      />
    </div>
  );
};

export default NumericInput;
