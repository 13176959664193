import React, { useState, useEffect, useRef, useContext } from 'react';
import { ClientContext } from '../../ClientContext';
import './DropdownWithSearch.css';
import './Modal.css';

const DropdownWithSearch = ({ items, selectedItem, setSelectedItem, label, setHomes, homes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editData, setEditData] = useState({ id: '', address: '', type: '' });
  const [message, setMessage] = useState('');
  const { selectedClient } = useContext(ClientContext);
  const dropdownRef = useRef(null);

  const filteredItems = items.filter(item =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
    setShowDropdown(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleEditClick = () => {
    setEditData(selectedItem || { id: '', address: '', type: '' });
    setShowEditModal(true);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleUpdateHome = async (homeId, address, type) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setMessage('No token found, please log in.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_home/${homeId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ address, type })
      });

      if (response.ok) {
        const updatedHome = await response.json();
        setHomes(homes.map(home => (home.id === homeId ? updatedHome : home)));
        setSelectedItem(updatedHome);
        setMessage('Home updated successfully.');
        setShowEditModal(false);
        setIsOpen(false);
      } else {
        const result = await response.json();
        setMessage(result.status);
      }
    } catch (error) {
      setMessage(`An error occurred: ${error.message}`);
    }
  };

  const handleDeleteHome = async (homeId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setMessage('No token found, please log in.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/delete_home/${homeId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (response.ok) {
        setMessage('Home deleted successfully.');
        setHomes(homes.filter(home => home.id !== homeId));
        setSelectedItem(null);
        setShowDeleteModal(false);
        setIsOpen(false);
        setShowDropdown(false);
      } else {
        const result = await response.json();
        setMessage(result.status);
      }
    } catch (error) {
      setMessage(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className="dropdown-with-search" ref={dropdownRef}>
      <label className="inputs-form-label">{label}:</label>
      <div className="dropdown-container">
        <div className="dropdown-selected-item" onClick={() => setIsOpen(!isOpen)}>
          {selectedItem ? selectedItem.label : `Select ${label.toLowerCase()}`}
        </div>
        {selectedItem && (
          <div
            className="home-dropdown"
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
          >
            <span className="three-dots">⋮</span>
            {showDropdown && (
              <div className="three-dots-menu">
                <div className="dropdown-item" onClick={handleEditClick}>Edit Home</div>
                <div className="dropdown-item" onClick={handleDeleteClick}>Delete Home</div>
              </div>
            )}
          </div>
        )}
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          <input
            type="text"
            className="inputs-search-input"
            placeholder={`Search ${label.toLowerCase()}...`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {filteredItems.length > 0 ? (
            filteredItems.map(item => (
              <div
                key={item.id}
                className="dropdown-item"
                onClick={() => handleSelectItem(item)}
              >
                {item.label}
              </div>
            ))
          ) : (
            <div className="dropdown-item">No results found</div>
          )}
        </div>
      )}
      {showEditModal && (
        <div className="modal-overlay" onClick={() => setShowEditModal(false)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h2>Edit Home</h2>
            <div className="input-group mb-4">
              <label className="input-label">Address</label>
              <input
                type="text"
                placeholder="Address"
                className="form-control"
                required
                value={editData.address}
                onChange={e => setEditData({ ...editData, address: e.target.value })}
              />
            </div>
            <div className="input-group mb-4">
              <label className="input-label">Type</label>
              <input
                type="text"
                placeholder="Type"
                className="form-control"
                required
                value={editData.type}
                onChange={e => setEditData({ ...editData, type: e.target.value })}
              />
            </div>
            <div className="modal-buttons">
              <button onClick={() => {
                handleUpdateHome(editData.id, editData.address, editData.type);
                setShowEditModal(false);
                setIsOpen(false);
              }}>Save</button>
              <button onClick={() => setShowEditModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {showDeleteModal && (
        <div className="modal-overlay" onClick={() => setShowDeleteModal(false)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h2>Confirm Delete</h2>
            <p>Are you sure you want to delete this home?</p>
            <div className="modal-buttons">
              <button onClick={() => {
                handleDeleteHome(selectedItem.id);
                setShowDeleteModal(false);
                setIsOpen(false);
              }}>Delete</button>
              <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {message && <p className="inputs-message">{message}</p>}
    </div>
  );
};

export default DropdownWithSearch;
