import React from 'react';
import { useLocation } from 'react-router-dom';
import './WelcomePage.css'; // Import the new CSS

const WelcomePage = () => {
  const location = useLocation();
  const client = location.state?.client || { name: 'Unknown Client' };

  return (
    <div className="welcome-container">
      <h1 className="welcome-title">Welcome, {client.name}!</h1>
      <p className="welcome-message">
        This presentation is tailored specifically for you, {client.name}. We aim to provide you with a comprehensive overview of your financial planning and goals. Our presentation will cover various aspects, including:
      </p>
      <ul className="welcome-list">
        <li>Personalized financial analysis</li>
        <li>Customized goal setting</li>
        <li>Detailed assumptions and projections</li>
        <li>Affordability and expenditure breakdown</li>
        <li>Overall financial health and recommendations</li>
      </ul>
      <p className="welcome-message">
        We hope this presentation provides you with valuable insights and helps you make informed decisions about your financial future.
      </p>
    </div>
  );
};

export default WelcomePage;
