import React, { useState } from 'react';
import './Hero.css';
import { FaAnchorCircleCheck, FaPeopleGroup } from "react-icons/fa6";
import { FaBuilding } from "react-icons/fa";
import { CgGym } from "react-icons/cg";

function Hero({ openSignup }) {
  const [openFaq, setOpenFaq] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  return (
    <div className="landing-hero">
      <div className="hero-content">
        <div className="hero-text">
          <h1>Assist in Your Mortgage Sales Process</h1>
          <p>We help loan officers streamline their sales process for mortgages, making it easier and more efficient.</p>
          <button onClick={openSignup} className="signup-button">Get Started</button>
        </div>
      </div>

      <div className="features">
        <div className="feature">
          <div className="feature-icon">
            <FaBuilding size={45} />
          </div>
          <h4>Streamline Sales</h4>
          <p>Utilize our tools to streamline the mortgage sales process, making it more efficient and effective.</p>
        </div>
        <div className="feature">
          <div className="feature-icon">
            <FaPeopleGroup size={45} />
          </div>
          <h4>Connect with Clients</h4>
          <p>Enhance your client relationships and close more deals with our integrated communication tools.</p>
        </div>
        <div className="feature">
          <div className="feature-icon">
            <FaAnchorCircleCheck size={45} />
          </div>
          <h4>Achieve Your Goals</h4>
          <p>Set and achieve your sales targets with the help of our powerful analytics and tracking features.</p>
        </div>
      </div>

      <div className="faq-section">
        <h5>Frequently Asked Questions</h5>
        <div className="faq-item" onClick={() => toggleFaq(0)}>
          <div className="faq-question">
            <div className="faq-q">
              <p>What is Pathfinder and how does it work?</p>
            </div>
            <span className={`faq-arrow ${openFaq === 0 ? 'open' : ''}`}>&#9654;</span>
          </div>
          {openFaq === 0 && <p>Pathfinder is a platform designed to assist loan officers in the mortgage sales process, offering tools and features to streamline operations and improve client interactions.</p>}
        </div>
        <div className="faq-item" onClick={() => toggleFaq(1)}>
          <div className="faq-question">
            <div className="faq-q">
              <p>How do I sign up for Pathfinder and what information is required?</p>
            </div>
            <span className={`faq-arrow ${openFaq === 1 ? 'open' : ''}`}>&#9654;</span>
          </div>
          {openFaq === 1 && <p>Signing up for Pathfinder is simple! Just click the "Get Started" button and provide basic information to begin streamlining your mortgage sales process.</p>}
        </div>
        <div className="faq-item" onClick={() => toggleFaq(2)}>
          <div className="faq-question">
            <div className="faq-q">
              <p>Is there any cost associated with using Pathfinder?</p>
            </div>
            <span className={`faq-arrow ${openFaq === 2 ? 'open' : ''}`}>&#9654;</span>
          </div>
          {openFaq === 2 && <p>Pathfinder offers various pricing plans to suit your needs. Contact us for more details on our plans and pricing.</p>}
        </div>
      </div>

      <footer className="footer">
        <div className="footer-content">
          <p>Contact Us</p>
          <div className="socials">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Hero;
