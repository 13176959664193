import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const calculateMonthlyPayment = (home_price, down_payment, interest_rate, term_years) => {
  const loan_amount = home_price * (1 - down_payment / 100);
  const monthly_interest_rate = interest_rate / 100 / 12;
  const number_of_payments = term_years * 12;
  return (
    (loan_amount * monthly_interest_rate) /
    (1 - Math.pow(1 + monthly_interest_rate, -number_of_payments))
  );
};

const HomeDetailsChart = ({ clientHomes }) => {
  const labels = clientHomes.map(home => home.address);
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Monthly Payment',
        data: clientHomes.map(home => 
          calculateMonthlyPayment(home.home_price, home.down_payment, home.interest_rate, 30)
        ),
        backgroundColor: clientHomes.map((_, index) => index % 2 === 0 ? '#3498db' : '#e67e22'),
      },
    ],
  };

  return (
    <div className="chart-section">
      <h3>Monthly Payment Comparison</h3>
      <Bar data={data} />
      <div className="comparison-table">
        <h3>Comparison Table</h3>
        <table>
          <thead>
            <tr>
              <th></th>
              {clientHomes.map((home, index) => (
                <th key={index}>{home.address}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total Loan Amount</td>
              {clientHomes.map((home, index) => (
                <td key={index}>${(home.home_price * (1 - home.down_payment / 100)).toFixed(2)}</td>
              ))}
            </tr>
            <tr>
              <td>DTI</td>
              {clientHomes.map((home, index) => (
                <td key={index}>{(home.down_payment / home.home_price * 100).toFixed(1)}%</td>
              ))}
            </tr>
            <tr>
              <td>LTV</td>
              {clientHomes.map((home, index) => (
                <td key={index}>{((home.home_price - (home.home_price * home.down_payment / 100)) / home.home_price * 100).toFixed(1)}%</td>
              ))}
            </tr>
            <tr>
              <td>Points</td>
              {clientHomes.map((home, index) => (
                <td key={index}>$0.00</td>
              ))}
            </tr>
            <tr>
              <td>Prepaid Int. Amount</td>
              {clientHomes.map((home, index) => (
                <td key={index}>${(home.home_price * home.interest_rate / 100 / 12).toFixed(2)}</td>
              ))}
            </tr>
            <tr>
              <td>Cash to Close</td>
              {clientHomes.map((home, index) => (
                <td key={index}>${(home.home_price * home.down_payment / 100 + (home.home_price * (1 - home.down_payment / 100)) * home.interest_rate / 100 / 12).toFixed(2)}</td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HomeDetailsChart;
