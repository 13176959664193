import { calculateLoanDetails, calculateComparison, formatNumber } from '../../../utils/mathUtils';

export const saveInputs = async (selectedHome, price, downPayment, isDownPaymentPercentage, interestRate, propertyTaxRate, homeInsuranceFee, PMIRate, HOA, rentAmount, setMessage, setLoanDetails, setComparisonData, selectedClient, filingStatus, income) => {
  const token = localStorage.getItem('token');
  if (!token) {
    setMessage('No token found, please log in.');
    return;
  }

  if (!selectedHome) {
    setMessage('Please select a home.');
    return;
  }

  const inputs = {
    home_id: selectedHome.id,
    home_price: price.replace(/,/g, ''),
    down_payment: isDownPaymentPercentage ? (parseFloat(price.replace(/,/g, '')) * (parseFloat(downPayment) / 100)).toString() : downPayment.replace(/,/g, ''),
    interest_rate: interestRate,
    property_tax_rate: propertyTaxRate,
    home_insurance_fee: homeInsuranceFee.replace(/,/g, ''),
    pmi_rate: PMIRate,
    hoa: HOA.replace(/,/g, ''),
    rent_amount: rentAmount.replace(/,/g, '')
  };

  const userInputs = {
    user_id: selectedClient.user_id,
    client_id: selectedClient.id,
    filing_status: filingStatus,
    income: income.replace(/,/g, '')
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/save_inputs`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({inputs, userInputs})
    });

    const result = await response.json();
    if (response.ok) {
      setMessage('Inputs saved successfully.');

      // Recalculate loan details and comparison data
      const loanDetails = calculateLoanDetails(inputs, 30); // Assuming 30 years by default
      setLoanDetails(loanDetails);

      const comparisonData = calculateComparison(inputs, 30, parseFloat(inputs.rent_amount), loanDetails.paymentList, loanDetails.totalInterest, loanDetails.propertyTaxes);
      setComparisonData(comparisonData);
    } else {
      setMessage(result.status);
    }
  } catch (error) {
    setMessage(`An error occurred: ${error.message}`);
  }
};

export const toggleDownPaymentType = (type, setIsDownPaymentPercentage, setDownPayment, downPayment, price, isDownPaymentPercentage) => {
  const currentTypeIsPercentage = type === 'percentage';
  if (currentTypeIsPercentage !== isDownPaymentPercentage) {
    setIsDownPaymentPercentage(currentTypeIsPercentage);
    if (currentTypeIsPercentage) {
      // Convert dollar amount to percentage
      setDownPayment(((parseFloat(downPayment.replace(/,/g, '')) / parseFloat(price.replace(/,/g, ''))) * 100).toFixed(2));
    } else {
      // Convert percentage to dollar amount
      setDownPayment(formatNumber((parseFloat(price.replace(/,/g, '')) * (parseFloat(downPayment) / 100)).toFixed(2)));
    }
  }
};

export const handleAddHome = async (selectedClient, newHomeAddress, newHomeType, setHomes, homes, setShowModal, setMessage, setNewHomeAddress, setNewHomeType) => {
  const token = localStorage.getItem('token');
  if (!token) {
    setMessage('No token found, please log in.');
    return;
  }

  if (!selectedClient) {
    setMessage('Please select a client.');
    return;
  }

  const newHome = {
    client_id: selectedClient.id,
    address: newHomeAddress,
    type: newHomeType
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/add_home`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newHome)
    });

    const result = await response.json();
    if (response.ok) {
      setHomes([...homes, result]);
      setNewHomeAddress('');
      setNewHomeType('');
      setShowModal(false);
      setMessage('Home added successfully.');
    } else {
      setMessage(result.status);
    }
  } catch (error) {
    setMessage(`An error occurred: ${error.message}`);
  }
};

export const handleUpdateHome = async (homeId, address, type, setHomes, homes, setShowEditModal, setMessage, setNewHomeAddress, setNewHomeType) => {
  const token = localStorage.getItem('token');
  if (!token) {
    setMessage('No token found, please log in.');
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_home/${homeId}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ address, type })
    });

    if (response.ok) {
      const updatedHome = await response.json();
      setHomes(homes.map(home => (home.id === homeId ? updatedHome : home)));
      setMessage('Home updated successfully.');
      setShowEditModal(false);
      setNewHomeAddress('');
      setNewHomeType('');
    } else {
      const result = await response.json();
      setMessage(result.status);
    }
  } catch (error) {
    setMessage(`An error occurred: ${error.message}`);
  }
};

export const handleDeleteHome = async (homeId, setHomes, homes, setMessage) => {
  const token = localStorage.getItem('token');
  if (!token) {
    setMessage('No token found, please log in.');
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/delete_home/${homeId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    if (response.ok) {
      setHomes(homes.filter(home => home.id !== homeId));
      setMessage('Home deleted successfully.');
    } else {
      const result = await response.json();
      setMessage(result.status);
    }
  } catch (error) {
    setMessage(`An error occurred: ${error.message}`);
  }
};
