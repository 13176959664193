import React from 'react';
import LoanInfoTable from '../components/LoanInfoTable';

const LoanInformation = ({ loanDetails, loanData, years, setYears, yearOptions }) => {
  return (
    <div className="loan-information">
      <h1>Loan Information</h1>
      <label>
        Years:
        <select value={years} onChange={(e) => setYears(parseInt(e.target.value, 10))}>
          {yearOptions.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </label>
      <LoanInfoTable loanDetails={loanDetails} loanData={loanData} years={years} />
    </div>
  );
};

export default LoanInformation;
