import React, { useState } from 'react';
import logo from '../../assets/pf.png';
import './Navbar.css';

function Navbar({ openLogin, openSignup }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="landing-navbar">
      <div className="logo">
        <a href="/">
          <img src={logo} alt="Pathfinder Logo" />
        </a>
      </div>

      <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
        ☰
      </button>

      <div className="auth-links">
        <button onClick={openLogin} className="login-button">Log In</button>
        <button onClick={openSignup} className="signup-button">Sign Up</button>
      </div>
    </nav>
  );
}

export default Navbar;
