// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AiOutlineUser, AiOutlineLock } from 'react-icons/ai';
import './Modal.css';

const Login = ({ onClose, openSignup }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);

    const loginData = {
      username: username,
      password: password,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.is_approved) {
          localStorage.setItem('token', data.token);  // Store token
          navigate('/HeaderLayout/Clients');
          onClose();
        } else {
          setLoginError('Your account is awaiting admin approval.');
        }
      } else {
        const text = await response.text();
        setLoginError(text);
      }
    } catch (error) {
      console.error('Error:', error);
      setLoginError('An error occurred during login.');
    } finally {
      setLoading(false);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <form onSubmit={handleLogin}>
          <div className="input-group mb-4">
            <div className="input-group-prepend">
              <span className="input-group-text">Username</span>
            </div>
            <input
              type="text"
              placeholder="Username"
              className="form-control"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-group mb-4">
            <div className="input-group-prepend">
              <span className="input-group-text">Password</span>
            </div>
            <input
              type="password"
              placeholder="Password"
              className="form-control"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="login-btn" style={{ cursor: loading ? 'wait' : 'default' }}>
            Login
          </button>
          {loginError && <p className="text-danger mt-3">{loginError}</p>}
          <div className="d-flex justify-content-between mt-5 bottom-text">
            <small><Link to="/ForgotPassword" onClick={onClose}>Forgot password?</Link></small>
            <small><Link to="#" onClick={() => { onClose(); openSignup(); }}>Don't have an account?</Link></small>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;