// src/utils/fetchLoanData.js
import { calculateLoanDetails, calculateComparison } from './mathUtils';

export const fetchClients = async (setClients, setMessage) => {
  const token = localStorage.getItem('token');
  if (!token) {
    setMessage('No token found, please log in.');
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_clients`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    const result = await response.json();
    if (response.ok) {
      setClients(result);
    } else {
      setMessage(result.status);
    }
  } catch (error) {
    setMessage(`An error occurred: ${error.message}`);
  }
};

export const fetchHomes = async (clientId, setHomes, setMessage) => {
  const token = localStorage.getItem('token');
  if (!token) {
    setMessage('No token found, please log in.');
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_homes?client_id=${clientId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    const result = await response.json();
    if (response.ok) {
      setHomes(result);
    } else {
      setMessage(`Error fetching homes: ${result.status}`);
    }
  } catch (error) {
    setMessage(`Error fetching homes: ${error.message}`);
  }
};

export const fetchClientData = async (clientId, setFilingStatus, setIncome, setMessage) => {
  const token = localStorage.getItem('token');
  if (!token) {
    setMessage('No token found, please log in.');
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_client_data?client_id=${clientId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    const result = await response.json();
    if (response.ok) {
      setFilingStatus(result.filing_status);
      setIncome(result.income);
    } else {
      setMessage(`Error fetching client data: ${result.status}`);
    }
  } catch (error) {
    setMessage(`Error fetching client data: ${error.message}`);
  }
};

export const fetchLoanData = async (homeId, years, setLoanData, setLoanDetails, setMessage) => {
  const token = localStorage.getItem('token');
  if (!token) {
    setMessage('No token found, please log in.');
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_inputs?home_id=${homeId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    const result = await response.json();
    if (response.ok) {
      const parsedResult = {
        home_price: parseFloat(result.home_price) || 0,
        down_payment: parseFloat(result.down_payment) || 0,
        interest_rate: parseFloat(result.interest_rate) || 0,
        property_tax_rate: parseFloat(result.property_tax_rate) || 0,
        home_insurance_fee: parseFloat(result.home_insurance_fee) || 0,
        pmi_rate: parseFloat(result.pmi_rate) || 0,
        hoa: parseFloat(result.hoa) || 0,
        rent_amount: parseFloat(result.rent_amount) || 0,
        filing_status: result.filing_status || 'Single',
        income: parseFloat(result.income) || 0,
      };
      setLoanData(parsedResult);
      const loanDetails = calculateLoanDetails(parsedResult, years);
      setLoanDetails(loanDetails);

      const comparisonData = calculateComparison(
        parsedResult,
        years,
        parseFloat(parsedResult.rent_amount),
        loanDetails.paymentList,
        loanDetails.totalInterest,
        loanDetails.propertyTaxes
      );
      // If you need to use setComparisonData, ensure it is passed as a parameter to this function.
    } else {
      setMessage(`Error fetching loan data: ${result.status}`);
    }
  } catch (error) {
    setMessage(`Error fetching loan data: ${error.message}`);
  }
};
