// src/components/LoanPayments.js
import React, { useState } from 'react';
import { formatNumber } from '../../utils/mathUtils';
import { Tooltip } from 'react-tooltip';
import './LoanPayments.css';

const LoanPayments = ({
  paymentList,
  showTableCells,
  setShowTableCells,
  selectedPaymentRange,
  setSelectedPaymentRange,
  loanDetails,
}) => {
  const handleShowTableCellsChange = (e) => {
    setShowTableCells(e.target.checked);
  };

  const filteredPayments = paymentList?.filter((payment) => {
    const paymentMonth = payment.paymentNumber;
    const selectedMonths = [0, 1, 2, 3, 6, 12, 24, 36, 48, 60, 120, 180, 240, 300, 360];
    return selectedMonths.includes(paymentMonth) && paymentMonth <= selectedPaymentRange;
  }) || [];

  const tooltipContent = (equation, values) => (
    `<div class="tooltip-content">
      <div class="equation">${equation}</div>
      <div class="values">${values}</div>
    </div>`
  );

  return (
    <div className="amortization-schedule">
      <h1>Loan Payments</h1>
      <label className="form-label">
        Select Payment Range:
        <select value={selectedPaymentRange} onChange={(e) => setSelectedPaymentRange(parseInt(e.target.value, 10))}>
          <option value={0}>0 months</option>
          <option value={1}>1 month</option>
          <option value={2}>2 months</option>
          <option value={3}>3 months</option>
          <option value={6}>6 months</option>
          <option value={12}>1 year</option>
          <option value={24}>2 years</option>
          <option value={36}>3 years</option>
          <option value={48}>4 years</option>
          <option value={60}>5 years</option>
          <option value={120}>10 years</option>
          <option value={180}>15 years</option>
          <option value={240}>20 years</option>
          <option value={300}>25 years</option>
          <option value={360}>30 years</option>
        </select>
      </label>
      <label className="form-label">
        <input type="checkbox" checked={showTableCells} onChange={handleShowTableCellsChange} />
        Show Table Cells
      </label>
      <table>
        <thead>
          <tr>
            <th>Period</th>
            <th>Payment ($)</th>
            <th>Interest</th>
            <th>Principal</th>
            <th>Loan</th>
          </tr>
        </thead>
        <tbody>
          {showTableCells && filteredPayments.length > 0 ? (
            filteredPayments.map((payment, index) => {
              const paymentMonth = payment.paymentNumber;
              let periodLabel = `${paymentMonth} months`;
              if (paymentMonth === 6) periodLabel = '6 months';
              else if (paymentMonth === 12) periodLabel = '1 year';
              else if (paymentMonth % 12 === 0 && paymentMonth > 12) periodLabel = `${paymentMonth / 12} years`;

              return (
                <tr key={index}>
                  <td>{periodLabel}</td>
                  <td>
                    <span
                      data-tooltip-id={`payment-tooltip-${index}`}
                      data-tooltip-html={tooltipContent(
                        'Payment Amount = Principal + Interest',
                        `${formatNumber(payment.principalAmount)} + ${formatNumber(payment.interestAmount)} = ${formatNumber(payment.paymentAmount)}`
                      )}
                      data-tooltip-delay-show="300"
                    >
                      ${formatNumber(payment.paymentAmount ?? 0)}
                    </span>
                    <Tooltip id={`payment-tooltip-${index}`} place="top" effect="solid" />
                  </td>
                  <td>
                    <span
                      data-tooltip-id={`interest-tooltip-${index}`}
                      data-tooltip-html={tooltipContent(
                        'Interest Amount = Remaining Balance * (Interest Rate / 12)',
                        `${formatNumber(payment.previousBalance)} * (${formatNumber(payment.interestRate)} / 12) = ${formatNumber(payment.interestAmount)}`
                      )}
                      data-tooltip-delay-show="300"
                    >
                      ${formatNumber(payment.interestAmount ?? 0)}
                    </span>
                    <Tooltip id={`interest-tooltip-${index}`} place="top" effect="solid" />
                  </td>
                  <td>
                    <span
                      data-tooltip-id={`principal-tooltip-${index}`}
                      data-tooltip-html={tooltipContent(
                        'Principal Amount = Payment Amount - Interest Amount',
                        `${formatNumber(payment.paymentAmount)} - ${formatNumber(payment.interestAmount)} = ${formatNumber(payment.principalAmount)}`
                      )}
                      data-tooltip-delay-show="300"
                    >
                      ${formatNumber(payment.principalAmount ?? 0)}
                    </span>
                    <Tooltip id={`principal-tooltip-${index}`} place="top" effect="solid" />
                  </td>
                  <td>
                    <span
                      data-tooltip-id={`balance-tooltip-${index}`}
                      data-tooltip-html={tooltipContent(
                        'Remaining Balance = Previous Balance - Principal Amount',
                        `${formatNumber(payment.previousBalance)} - ${formatNumber(payment.principalAmount)} = ${formatNumber(payment.remainingBalance)}`
                      )}
                      data-tooltip-delay-show="300"
                    >
                      ${formatNumber(payment.remainingBalance ?? 0)}
                    </span>
                    <Tooltip id={`balance-tooltip-${index}`} place="top" effect="solid" />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="5">No payments to show</td>
            </tr>
          )}
          <tr>
            <td colSpan="2">Sum of Interest</td>
            <td colSpan="3">
              <span
                data-tooltip-id="sum-interest-tooltip"
                data-tooltip-html={tooltipContent(
                  'Sum of Interest = Sum of all interest payments until selected payment range',
                  `${formatNumber((loanDetails?.paymentList ?? []).slice(0, selectedPaymentRange).reduce((sum, payment) => sum + (payment.interestAmount ?? 0), 0))}`
                )}
                data-tooltip-delay-show="300"
              >
                ${formatNumber((loanDetails?.paymentList ?? []).slice(0, selectedPaymentRange).reduce((sum, payment) => sum + (payment.interestAmount ?? 0), 0))}
              </span>
              <Tooltip id="sum-interest-tooltip" place="top" effect="solid" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LoanPayments;
