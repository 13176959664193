import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const MonthlyPaymentComparison = ({ homes }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Monthly Payment',
        data: [],
        backgroundColor: 'blue',
      },
    ],
  });

  useEffect(() => {
    if (homes.length > 0) {
      const labels = homes.map((home) => home.address);
      const data = homes.map((home) => calculateMonthlyPayment(home));

      setChartData({
        labels: labels,
        datasets: [
          {
            label: 'Monthly Payment',
            data: data,
            backgroundColor: 'blue',
          },
        ],
      });
    }
  }, [homes]);

  const calculateMonthlyPayment = (home) => {
    const principal = home.home_price - home.down_payment;
    const monthlyInterestRate = home.interest_rate / 100 / 12;
    const numberOfPayments = home.term_months;

    // Monthly payment calculation using the formula for a fixed-rate mortgage
    const monthlyPayment =
      (principal * monthlyInterestRate) /
      (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

    return parseFloat(monthlyPayment.toFixed(2));
  };

  return (
    <div className="monthly-payment-comparison-chart">
      <h2>Monthly Payment Comparison</h2>
      <Bar data={chartData} />
    </div>
  );
};

export default MonthlyPaymentComparison;
