import React, { useState, useEffect, useContext, useCallback } from 'react';
import { ClientContext } from '../ClientContext';
import { fetchLoanData, fetchHomes, fetchClientData, fetchClients } from '../../utils/fetchLoanData';
import LoanInformation from '../components/LoanInformation';
import LoanPayments from '../components/LoanPayments';
import TaxRateCalculator from '../components/TaxRateCalculator';
import LoanDetails from '../components/LoanDetails';
import DropdownWithSearch from '../components/DropdownWithSearch';
import './MortgageCalculator.css';

function MortgageCalculator({
  setLoanData,
  setLoanDetails,
  setPaymentList,
  setSelectedClient,
  setSelectedPaymentRange,
  setFilingStatus,
  setIncome,
  setYears,
  rentAmount,
  setRentAmount,
  setPropertyTaxes,
  setTotalInterest,
  setTaxRate
}) {
  
  const { selectedClient, setSelectedClient: setGlobalSelectedClient, loanDetails, selectedHome, setSelectedHome, filingStatus, income, taxRate } = useContext(ClientContext);
  const [clients, setClients] = useState([]);
  const [loanData, setInternalLoanData] = useState({
    home_price: 0,
    down_payment: 0,
    interest_rate: 0,
    property_tax_rate: 0,
    home_insurance_fee: 0,
    pmi_rate: 0,
    hoa: 0,
    rent_amount: 0,
    filing_status: 'Single',
    income: 0,
  });
  const [years, setInternalYears] = useState(30);
  const [selectedPaymentRange, setInternalSelectedPaymentRange] = useState(0);
  const [showTableCells, setShowTableCells] = useState(false);
  const [message, setMessage] = useState('');
  const [paymentList, setInternalPaymentList] = useState([]);
  const [propertyTaxes, setInternalPropertyTaxes] = useState(0);
  const [totalInterest, setInternalTotalInterest] = useState(0);
  const [homes, setHomes] = useState([]);

  const yearOptions = [15, 20, 25, 30];

  useEffect(() => {
    console.log('Fetching clients...');
    fetchClients(setClients, setMessage);
  }, []);

  useEffect(() => {
    if (selectedClient) {
      console.log('Fetching homes for client:', selectedClient.id);
      fetchHomes(selectedClient.id, setHomes, setMessage);
      fetchClientData(selectedClient.id, setFilingStatus, setIncome, setMessage); // Fetch client data directly
    }
  }, [selectedClient]);

  const fetchLoanDataForClient = useCallback(() => {
    if (selectedClient && selectedHome) {
      console.log('Fetching loan data for client:', selectedClient, 'and home:', selectedHome);
      fetchLoanData(selectedHome.id, years, (data) => {
        setInternalLoanData(data);
        setLoanData(data);
        setFilingStatus(data.filing_status);
        setIncome(data.income);
      }, setLoanDetails, setMessage);
    }
  }, [selectedClient, selectedHome, years, setLoanData, setLoanDetails, setFilingStatus, setIncome]);

  useEffect(() => {
    if (selectedHome) {
      console.log('Selected home changed:', selectedHome);
      fetchLoanDataForClient();
    }
  }, [fetchLoanDataForClient, selectedHome]);

  useEffect(() => {
    console.log('Updating context and state with loan details and client information...');
    if (loanDetails && loanDetails.paymentList) {
      console.log('Setting internal payment list...');
      setInternalPaymentList(loanDetails.paymentList);
    }
    setLoanData(loanData);
    setSelectedClient(selectedClient);
    setGlobalSelectedClient(selectedClient);  // Update global context
    setSelectedPaymentRange(selectedPaymentRange);
    setFilingStatus(filingStatus);
    setIncome(income);
    setYears(years);
    setRentAmount(rentAmount);  // Use rentAmount
    setPropertyTaxes(propertyTaxes);
    setTotalInterest(totalInterest);
    setTaxRate(taxRate);

    console.log('loanData:', loanData);
    console.log('selectedClient:', selectedClient);
    console.log('filingStatus:', filingStatus);
    console.log('income:', income);
    console.log('years:', years);
    console.log('rentAmount:', rentAmount);
    console.log('propertyTaxes:', propertyTaxes);
    console.log('totalInterest:', totalInterest);
    console.log('taxRate:', taxRate);
  }, [loanData, loanDetails, selectedClient, selectedPaymentRange, filingStatus, income, years, rentAmount, propertyTaxes, totalInterest, taxRate, setGlobalSelectedClient]);

  const handleRentChange = (event) => {
    const newRentAmount = parseFloat(event.target.value);
    console.log('Rent amount in MortgageCalculator:', newRentAmount);
    setRentAmount(newRentAmount);
  };

  return (
    <div className="mortgage-calculator">
      <div className="top-row">
        <div className="dropdowns">
          <DropdownWithSearch
            items={clients.map(client => ({ id: client.id, label: `${client.first_name} ${client.last_name}` }))}
            selectedItem={selectedClient}
            setSelectedItem={(client) => {
              setSelectedClient(client);
              setGlobalSelectedClient(client);  // Update global context
              setSelectedHome(null);  // Reset selected home
            }}
            label="Clients"
          />
          {selectedClient && (
            <DropdownWithSearch
              items={homes.map(home => ({ id: home.id, label: home.address }))}
              selectedItem={selectedHome}
              setSelectedItem={(home) => {
                setSelectedHome(home);
                localStorage.setItem('selectedHome', JSON.stringify(home));  // Save selected home to localStorage
              }}
              label="Homes"
            />
          )}
        </div>
      </div>
      <div className="middle-row">
        {selectedClient && <LoanDetails loanData={{...loanData, filingStatus, income}} />}
        {selectedHome && (
          <LoanInformation
            loanDetails={loanDetails}
            loanData={loanData}
            years={years}
            setYears={setInternalYears}
            yearOptions={yearOptions}
          />
        )}
      </div>
      {selectedHome && (
        <>
          <div className="finance-info">
            <LoanPayments
              paymentList={paymentList}
              showTableCells={showTableCells}
              setShowTableCells={setShowTableCells}
              selectedPaymentRange={selectedPaymentRange}
              setSelectedPaymentRange={setInternalSelectedPaymentRange}
              loanDetails={loanDetails}
            />
            <TaxRateCalculator />
          </div>
        </>
      )}
    </div>
  );
}

export default MortgageCalculator;
