import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { ClientContext } from '../ClientContext';
import './TaxRateCalculator.css';

const calculateTaxRate = (filingStatus, income) => {
  const taxBrackets = {
    Single: [
      { min: 0, max: 9875, rate: 10 },
      { min: 9876, max: 40125, rate: 12 },
      { min: 40126, max: 85525, rate: 22 },
      { min: 85526, max: 163300, rate: 24 },
      { min: 163301, max: 207350, rate: 32 },
      { min: 207351, max: 518400, rate: 35 },
      { min: 518401, max: Infinity, rate: 37 }
    ],
    Jointly: [
      { min: 0, max: 19750, rate: 10 },
      { min: 19751, max: 80250, rate: 12 },
      { min: 80251, max: 171050, rate: 22 },
      { min: 171051, max: 326600, rate: 24 },
      { min: 326601, max: 414700, rate: 32 },
      { min: 414701, max: 622050, rate: 35 },
      { min: 622051, max: Infinity, rate: 37 }
    ],
    HeadOfHousehold: [
      { min: 0, max: 14100, rate: 10 },
      { min: 14101, max: 53700, rate: 12 },
      { min: 53701, max: 85500, rate: 22 },
      { min: 85501, max: 163300, rate: 24 },
      { min: 163301, max: 207350, rate: 32 },
      { min: 207351, max: 518400, rate: 35 },
      { min: 518401, max: Infinity, rate: 37 }
    ]
  };

  const brackets = taxBrackets[filingStatus];
  const incomeNumber = parseFloat(income.replace(/,/g, ''));

  console.log('Income Number:', incomeNumber); // Debug income number

  if (!incomeNumber) {
    console.log('Invalid Income Number'); // Debug invalid income
    return null;
  }

  for (let i = brackets.length - 1; i >= 0; i--) {
    if (incomeNumber >= brackets[i].min) {
      console.log('Tax Bracket Found:', brackets[i]); // Debug bracket found
      return brackets[i].rate;
    }
  }
  console.log('No Tax Bracket Found'); // Debug no bracket found
  return null;
};

const TaxRateCalculator = () => {
  const { clientId, filingStatus, income, setFilingStatus, setIncome } = useContext(ClientContext);
  const [taxRate, setTaxRate] = useState(null);
  const [showTaxRate, setShowTaxRate] = useState(false);

  useEffect(() => {
    const fetchUserInputs = async () => {
      try {
        console.log('Fetching user inputs for client ID:', clientId); // Debug client ID
        const response = await axios.get('/get_user_inputs', {
          params: { client_id: clientId }
        });
        if (response.status === 200) {
          const { filing_status, income } = response.data;
          console.log('Fetched Data:', { filing_status, income }); // Debug fetched data
          setFilingStatus(filing_status);
          const formattedIncome = income.toLocaleString();
          setIncome(formattedIncome);
        } else {
          console.log('Error fetching data:', response.status); // Debug response status
        }
      } catch (error) {
        console.error('Error fetching user inputs:', error);
      }
    };

    if (clientId) {
      fetchUserInputs();
    }
  }, [clientId, setFilingStatus, setIncome]);

  const calculateTaxRateHandler = () => {
    const rate = calculateTaxRate(filingStatus, income);
    console.log('Calculated Tax Rate:', rate); // Debug calculated tax rate
    setTaxRate(rate);
    setShowTaxRate(true);
  };

  return (
    <div className="TaxRateCalc">
      <h1>Tax Rate Calculator</h1>
      <label htmlFor="filingStatus">Filing Status:</label>
      <input type="text" id="filingStatus" value={filingStatus} disabled />
      <br />
      <label htmlFor="income">Income:</label>
      <input type="text" id="income" value={income} disabled />
      <br />
      <button onClick={calculateTaxRateHandler}>Calculate Tax Rate</button>
      {showTaxRate && taxRate !== null && (
        <h2>Your tax rate is: {taxRate}%</h2>
      )}
    </div>
  );
};

export default TaxRateCalculator;
