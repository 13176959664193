// src/pages/Preview.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ComparisonSectionClient from '../../components/clientComps/ComparisonSectionClient';
import MonthlyPaymentComparison from '../../components/clientComps/MonthlyPaymentComparison';
import ComparisonTable from '../../components/clientComps/ComparisonTable';
import { fetchHomes } from '../../components/clientComps/fetchLoanDataClient';
import './Preview.css';

const Preview = () => {
  const { presentationId } = useParams();
  const [homes, setHomes] = useState([]);

  useEffect(() => {
    // Fetch homes based on the presentation ID (client ID)
    fetchHomes(presentationId)
      .then((fetchedHomes) => setHomes(fetchedHomes))
      .catch((error) => {
        console.error('Error fetching homes:', error);
      });
  }, [presentationId]);

  return (
    <div className="preview-container">
      <h1>Presentation Preview</h1>
      <div className="comparison-section">
        <h2>Rent vs Own Comparison</h2>
        <ul>
          {homes.map((home) => (
            <li key={home.id}>
              <h3>{home.address}</h3>
              <ComparisonSectionClient home={home} />
            </li>
          ))}
        </ul>
      </div>

      <div className="monthly-payment-comparison">
        <MonthlyPaymentComparison homes={homes} />
      </div>

      <div className="comparison-table">
        <ComparisonTable homes={homes} />
      </div>
    </div>
  );
};

export default Preview;
