export const saveEditedComparisonDetail = async (year, field, value) => {
    const token = localStorage.getItem('token');
    if (!token) {
        console.error('No token found, please log in.');
        return;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/save_comparison_detail`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ year, field, value })
        });

        if (response.ok) {
            console.log('Comparison detail saved successfully.');
        } else {
            const result = await response.json();
            console.error('Error saving comparison detail:', result.status);
        }
    } catch (error) {
        console.error('An error occurred while saving comparison detail:', error);
    }
};
