import React, { useState, useEffect, useContext } from 'react';
import MortgageCalculator from '../../components/MortgageCalculator';
import ComparisonSection from '../../components/ComparisonSection';
import { generatePDF } from '../../../utils/generatePDF';
import { calculateComparisonForChart } from '../../../utils/mathUtils';
import { ClientContext } from '../../ClientContext';
import './Simple.css';

function Simple() {
  const {
    selectedClient,
    setSelectedClient,
    loanDetails,
    setLoanDetails,
    filingStatus,
    setFilingStatus,
    income,
    setIncome
  } = useContext(ClientContext);

  const [comparisonResults, setComparisonResults] = useState([]);
  const [expandedYear, setExpandedYear] = useState(null);
  const [loanData, setLoanData] = useState({});
  const [paymentList, setPaymentList] = useState([]);
  const [selectedPaymentRange, setSelectedPaymentRange] = useState(0);
  const [years, setYears] = useState(30);
  const [propertyTaxes, setPropertyTaxes] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [taxRate, setTaxRate] = useState(null);
  const [rentAmount, setRentAmount] = useState(0);
  const [rentIncreases, setRentIncreases] = useState(0.03);


  useEffect(() => {
    const fetchLoanData = async () => {
      try {
        const response = await fetch('/api/loandata'); // Replace with actual API endpoint
        const data = await response.json();
        setLoanData(data);
        setRentAmount(data.rent_amount);
        setRentIncreases(data.rent_increases || 0.03); // Assuming this is fetched from the database
      } catch (error) {
        console.error('Error fetching loan data:', error);
      }
    };

    fetchLoanData();
  }, []);

  useEffect(() => {
    if (loanDetails) {
      const results = calculateComparisonForChart(loanData, years, rentAmount);
      setComparisonResults(results);
    }
  }, [loanDetails, years, rentAmount, loanData]);

  const handleGeneratePDF = () => {
    generatePDF(
      selectedClient,
      loanData,
      loanDetails,
      paymentList,
      selectedPaymentRange,
      comparisonResults,
      filingStatus,
      income,
      years,
      rentAmount,
      propertyTaxes,
      totalInterest
    );
  };

  return (
    <div className="simple-container">
      <MortgageCalculator
        setLoanData={setLoanData}
        setLoanDetails={setLoanDetails}
        setPaymentList={setPaymentList}
        setSelectedClient={setSelectedClient}
        setSelectedPaymentRange={setSelectedPaymentRange}
        setFilingStatus={setFilingStatus}
        setIncome={setIncome}
        setYears={setYears}
        rentAmount={rentAmount}
        setRentAmount={setRentAmount}
        setPropertyTaxes={setPropertyTaxes}
        setTotalInterest={setTotalInterest}
        setTaxRate={setTaxRate}
      />
      <ComparisonSection
        comparisonResults={comparisonResults}
        setComparisonResults={setComparisonResults}
        expandedYear={expandedYear}
        setExpandedYear={setExpandedYear}
        paymentList={paymentList}
        propertyTaxes={propertyTaxes}
        years={years}
        totalInterest={totalInterest}
        rentAmount={rentAmount}
        rentIncreases={rentIncreases}
      />
      <button className="generate-pdf-button" onClick={handleGeneratePDF}>Generate PDF</button>
    </div>
  );
}

export default Simple;
