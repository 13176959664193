import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
    const [newEmail, setNewEmail] = useState('');
    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [feedback, setFeedback] = useState('');
    const navigate = useNavigate();

    const handleChangeEmail = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/change_email`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ new_email: newEmail, password })
            });

            if (response.ok) {
                alert('Email updated successfully');
            } else {
                const errorData = await response.json();
                alert(`Failed to update email: ${errorData.msg}`);
            }
        } catch (error) {
            console.error('Error changing email:', error);
        }
    };

    const handleChangePassword = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/change_password`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ old_password: oldPassword, new_password: newPassword })
            });

            if (response.ok) {
                alert('Password updated successfully');
            } else {
                const errorData = await response.json();
                alert(`Failed to update password: ${errorData.msg}`);
            }
        } catch (error) {
            console.error('Error changing password:', error);
        }
    };

    const handleDeleteAccount = async () => {
        const confirmed = window.confirm('Are you sure you want to delete your account? This action is permanent and cannot be undone.');
        if (!confirmed) {
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/delete_account`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                localStorage.removeItem('token');
                navigate('/');
                alert('Account deleted successfully');
            } else {
                const errorData = await response.json();
                alert(`Failed to delete account: ${errorData.msg}`);
            }
        } catch (error) {
            console.error('Error deleting account:', error);
        }
    };

    const handleSubmitFeedback = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/feedback`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ feedback })
            });

            if (response.ok) {
                alert('Feedback submitted successfully');
                setFeedback('');
            } else {
                const errorData = await response.json();
                alert(`Failed to submit feedback: ${errorData.msg}`);
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }
    };

    return (
        <div className="settings-container">
            <h2>Settings</h2>
            <div className="form-group">
                <h3>Change Email</h3>
                <input type="email" value={newEmail} onChange={e => setNewEmail(e.target.value)} placeholder="New Email" />
                <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" />
                <button onClick={handleChangeEmail}>Change Email</button>
            </div>
            <div className="form-group">
                <h3>Change Password</h3>
                <input type="password" value={oldPassword} onChange={e => setOldPassword(e.target.value)} placeholder="Old Password" />
                <input type="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} placeholder="New Password" />
                <button onClick={handleChangePassword}>Change Password</button>
            </div>
            <div className="form-group">
                <h3>Delete Account</h3>
                <button onClick={handleDeleteAccount}>Delete Account</button>
            </div>
            <div className="form-group">
                <h3>Feedback</h3>
                <textarea value={feedback} onChange={e => setFeedback(e.target.value)} placeholder="Your feedback" />
                <button onClick={handleSubmitFeedback}>Submit Feedback</button>
            </div>
        </div>
    );
};

export default Settings;
